// Global Nav Position Setting
document.addEventListener('DOMContentLoaded', function () {
    const mq = window.matchMedia('(max-width: 1024px)');

    function checkBreakPoint(mq) {
        if (mq.matches) {
            $('.gNav').prependTo('#offCanvas .sp-nav-wrapper');
            $('.subNav').prependTo('#offCanvas .sp-nav-footer');
            $('.search-form-wrapper').insertAfter('.subNav');
        } else {
            $('.gNav').insertAfter('header');
            $('.subNav').appendTo('.header-menu');
            $('.search-form-wrapper').prependTo('.header-menu');
        }
    }
    mq.addListener(checkBreakPoint);
    checkBreakPoint(mq);
    // Page top
    $(function () {
        var pagetop = $('#page_top');
        pagetop.hide();
        $(window).scroll(function () {
            if ($(this).scrollTop() > 300) {  //100pxスクロールしたら表示
                pagetop.fadeIn();
            } else {
                pagetop.fadeOut();
            }
        });
    });
    // 同意事項
    $(_ => {
        const $_btn = $('#btn'),
            $_check = $('[id^="check"]').prop('checked', false);
        $_check.on('change', _ => {
            const ck = $_check.length === $_check.filter(':checked').length;
            $_btn.prop('disabled', !ck)[ck ? 'removeClass' : 'addClass']('inactive');
        }).change();
    });

});